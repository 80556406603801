import React, { Component } from "react";

export default class LargeImage extends Component {
  getSources(prefix, queries) {
    return queries.map((q, i) => (
      <React.Fragment key={this.props.name + "_" + i}>
        <source
          key={this.props.name + "_png_" + i}
          media={`(min-width: ${q.width}px)`}
          type="image/webp"
          srcSet={require(prefix + "-" + q.suffix + ".webp")}
        />
        <source
          key={this.props.name + "_webp_" + i}
          media={`(min-width: ${q.width}px)`}
          type="image/png"
          srcSet={require(prefix + "-" + q.suffix + ".png")}
        />
      </React.Fragment>
    ));
  }

  render() {
    // bootstrap media queries:
    // xs = Extra small <576px
    // sm = Small ≥576px
    // md = Medium ≥768px
    // lg = Large ≥992px
    // xl = Extra large ≥1200px

    const prefix = "./img/" + this.props.name + "/" + this.props.name;
    const queries = [
      { width: 992, suffix: "xl" },
      { width: 768, suffix: "lg" },
      { width: 576, suffix: "md" },
      { width: 1, suffix: "sm" },
    ];

    const style = { maxWidth: "1200px" };

    return (
      <picture>
        {this.getSources(prefix, queries)}
        <img
          style={style}
          className=" mx-2 my-3 img-fluid w-100 rounded shadow"
          src={require("./img/" +
            this.props.name +
            "/" +
            this.props.name +
            "-lg.png")}
          alt={this.props.alt}
        />
      </picture>
    );
  }
}
